import React, { Component, createRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Input, Button } from 'semantic-ui-react';
import { CSSTransition } from 'react-transition-group';

import searchSVG from '../../static/search.svg';

import config from '@plone/volto/registry';

const SearchInput = forwardRef(
  (
    {
      forwardRef,
      placeholder,
      className,
      value,
      onChange,
      onSubmit,
      onSelectSuggestion,
    },
    ref,
  ) => {
    const SolrSearchAutosuggest = config.widgets.SolrSearchAutosuggest;
    return (
      <SolrSearchAutosuggest
        inputRef={ref}
        placeholder={placeholder}
        className={className}
        value={value || ''}
        onChange={onChange}
        onSubmit={onSubmit}
        onSelectSuggestion={onSelectSuggestion}
      />
    );
  },
);

/**
 *
 *
 * @class InlineSearch
 * @extends {Component}
 */
class InlineSearch extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    closeMenu: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
    this.searchInputRef = createRef();
  }

  componentDidMount() {
    this.searchInputRef.current.focus();
  }

  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText = (event) => {
    this.setState({
      text: event.target.value,
    });
  };

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit = (event) => {
    this.props.closeMenu(event);
    this.props.history.push(`/search?SearchableText=${this.state.text}`);
    event.preventDefault();
  };

  getItemURL = (item) => {
    let type;
    if (item.portal_type) {
      type = item.portal_type;
    } else {
      type = item['@type'];
    }
    switch (type) {
      case 'jungzeelandia.Recipe':
        return `/alle-rezepte/${item.id}`;
      case 'jungzeelandia.Product':
        return `/alle-produkte/${item.id}`;
      case 'jungzeelandia.SalesConcept':
      case 'Document':
      case 'Image':
      case 'News Item':
        return item['@id'];
      default:
        break;
    }
    return '';
  };

  getItemPrefix = (item) => {
    switch (item.portal_type) {
      case 'jungzeelandia.Recipe':
        return 'Rezept: ';
      case 'jungzeelandia.Product':
        return 'Produkt: ';
      case 'Jungzeelandia.SalesConcept':
        return 'Verkaufskonzept: ';
      default:
        return '';
    }
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Form action="/search" onSubmit={this.onSubmit} className="inline-search">
        <h1>Suche nach:</h1>
        <Form.Field className="searchbox" style={{ margin: 0 }}>
          <SearchInput
            ref={this.searchInputRef}
            placeholder="Suchbegriff hier eingeben"
            className="searchinput"
            tabIndex={0}
            value={this.state.text}
            onChange={this.onChangeText}
            onSubmit={this.onSubmit}
            onSelectSuggestion={this.props.closeMenu}
          />

          {/* <Input
            onChange={this.onChangeText}
            name="SearchableText"
            value={this.state.text}
            transparent
            tabIndex={0}
            placeholder="Suchbegriff hier eingeben"
            title="Suche"
            autoComplete="off"
            ref={this.searchInputRef}
          /> */}
          <Button>
            <img src={searchSVG} alt="search" />
          </Button>
        </Form.Field>
      </Form>
    );
  }
}

export default withRouter(InlineSearch);
